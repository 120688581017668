import React from 'react';
import styled from 'styled-components';
import bp from '../assets/js/breakpoints';
import ArrowIcon from './Icons/ArrowIcon';

const Button = React.forwardRef(
  ({ as, children, onClick, withArrow, href, target }, ref) => {
    return (
      <Wrapper
        as={as ? as : href ? 'a' : 'button'}
        className="button-wrapper"
        onClick={onClick}
        ref={ref}
        withArrow={withArrow}
        href={href}
        target={target}
        rel={target === '_blank' ? 'noreferrer' : null}
      >
        {children}
        {withArrow && <ArrowIcon size={16} />}
      </Wrapper>
    );
  }
);

export default Button;

/**
 * Styled Components
 *
 */
const Wrapper = styled.button`
  --border-radius: 10rem;

  background-color: var(--color-snow);
  position: relative;
  border-radius: var(--border-radius);
  padding: var(--space-xs) var(--space-s);
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: -0.05rem;
  color: var(--color-navy);
  border: 0.1rem solid var(--color-navy);
  cursor: pointer;
  z-index: 1;
  overflow: hidden;
  display: flex;
  align-items: center;
  transition: all 200ms var(--ease-cubic-in-out);

  @media (${bp.min.sm}) {
    font-size: 1.5rem;
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-navy);
      color: var(--color-snow);

      .arrow-icon {
        stroke: var(--color-snow);
        transform: translateX(0.4rem);
      }
    }
  }

  .arrow-icon {
    stroke: var(--color-navy);
    margin-left: var(--space-xs);
    transition: all 200ms var(--ease-cubic-in-out);
  }
`;
