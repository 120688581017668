import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay } from 'swiper';

import bp from '../assets/js/breakpoints';

import Button from './Button';
import Image from './Image';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
import Link from './Link';

const HomepageSlider = () => {
  const staticData = useStaticQuery(graphql`
    {
      prismicHomepage {
        _previewable
        data {
          slideshow_images {
            image {
              gatsbyImageData
            }
          }
          slideshow_headline {
            html
          }
          slideshow_button {
            text
            link {
              link_type
              url
            }
          }
        }
      }
    }
  `);

  // eslint-disable-next-line no-unused-vars
  const { data, isPreview } = useMergePrismicPreviewData(staticData);

  const document = data.prismicHomepage;
  const { slideshow_images, slideshow_headline, slideshow_button } =
    document.data;

  return (
    <Wrapper className="full-width-container">
      <Link
        url={{
          url: slideshow_button[0].link.url,
          link_type: slideshow_button[0].link.link_type,
        }}
      >
        <Swiper
          centeredSlides={true}
          loop={true}
          loopedSlides={slideshow_images.length}
          slidesPerView={1}
          spaceBetween={0}
          modules={[EffectFade, Autoplay]}
          effect={'fade'}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
        >
          {slideshow_images.map((image, i) => (
            <SwiperSlide key={i} className="swiper-slide">
              <Image image={image.image} />
            </SwiperSlide>
          ))}
        </Swiper>

        <TextWrapper>
          <p dangerouslySetInnerHTML={{ __html: slideshow_headline.html }} />

          <Button withArrow>{slideshow_button[0].text}</Button>
        </TextWrapper>
      </Link>
    </Wrapper>
  );
};

export default HomepageSlider;

/**
 * Styled Components
 *
 */
const Wrapper = styled.section`
  /* margin-bottom: var(--space-m); */
  position: relative;

  .swiper-slide {
    width: 100%;
    max-height: 90vh;
    aspect-ratio: 2 / 3;
    overflow: hidden;

    @media (${bp.min.sm}) {
      aspect-ratio: 2 / 1;
    }

    .image-wrapper,
    .image {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }
`;

const TextWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 var(--page-padding) var(--space-l);

  @media (${bp.min.sm}) {
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
    padding: 0 var(--page-padding) var(--space-xl);
  }

  p {
    font-weight: var(--font-weight-regular);
    font-size: clamp(4.4rem, 3.5vw, 6rem);
    line-height: 1.1;
    letter-spacing: -0.25rem;
    color: var(--color-snow);
    margin-bottom: var(--space-m);

    @media (${bp.min.sm}) {
      margin-bottom: 0;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 200%;
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0)
      );
      z-index: -1;
    }

    br {
      display: none;

      @media (${bp.min.xs_sm}) {
        display: block;
      }
    }
  }

  .button-wrapper {
    white-space: nowrap;
    display: inline-flex;
  }
`;
