import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import styled from 'styled-components';

import bp from '../assets/js/breakpoints';

import Image from './Image';
import Link from './Link';

const LinkCard = ({ data }) => {
  const { image, text, link } = data;

  return (
    <CardWrapper>
      <Link url={{ url: link.url, link_type: link.link_type }}>
        <ImageWrapper>
          <Image image={image} aspectRatio={1 / 1} />
        </ImageWrapper>

        <p>{text}</p>
      </Link>
    </CardWrapper>
  );
};

const HomepageLinkGrid = () => {
  const staticData = useStaticQuery(graphql`
    {
      prismicHomepage {
        _previewable
        data {
          link_card {
            image {
              gatsbyImageData
            }
            text
            link {
              link_type
              target
              url
            }
          }
        }
      }
    }
  `);

  // eslint-disable-next-line no-unused-vars
  const { data, isPreview } = useMergePrismicPreviewData(staticData);

  const document = data.prismicHomepage;

  const { link_card } = document.data;

  return (
    <Wrapper>
      {link_card.map((card, i) => (
        <LinkCard data={card} key={i} />
      ))}
    </Wrapper>
  );
};

export default HomepageLinkGrid;

/**
 * Styled Components
 *
 */
const Wrapper = styled.ul`
  --grid-layout-gap: var(--page-padding);
  --grid-column-count: 1;

  position: relative;
  display: grid;
  grid-template-columns: repeat(var(--grid-column-count), 1fr);
  grid-gap: var(--grid-layout-gap);
  padding: var(--page-padding) 0;

  @media (${bp.min.xs}) {
    --grid-layout-gap: 2rem;
    --grid-column-count: 2;
  }

  @media (${bp.min.sm}) {
    --grid-column-count: 4;
  }

  &:after {
    content: '';
    width: 100vw;
    height: 100%;
    background-color: var(--color-snow);
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    z-index: -1;
  }
`;

const CardWrapper = styled.li`
  background-color: var(--color-navy);
  transition: background-color 300ms var(--ease-cubic-in-out);

  &:hover {
    background-color: var(--color-navy-light);

    p {
      color: var(--color-navy);
    }

    .image img {
      transform: scale(1.05);
    }
  }

  a {
    padding: var(--space-xxs);
    display: block;
  }

  p {
    font-size: clamp(1.8rem, 2vw, 2.4rem);
    line-height: 1;
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
    text-align: center;
    color: var(--color-snow);
    margin: var(--space-xs) 0 var(--space-xxxs);
    transition: color 300ms var(--ease-cubic-in-out);
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;

  .image img {
    width: 101%;
    height: 100%;
    object-fit: cover;
    will-change: transform;
    transition: transform 300ms var(--ease-cubic-in-out);
  }
`;
