import React from 'react';
import { useStaticQuery, graphql, Link as GatsbyLink } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import styled from 'styled-components';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

import bp from '../assets/js/breakpoints';
import { getContrastingColor } from '../assets/js/utilities';

import NewStoryBadge from './NewStoryBadge';
import Image from './Image';
import Link from './Link';
import ListenWatchButton from './ListenWatchButton';

const LatestStory = () => {
  const staticData = useStaticQuery(graphql`
    query {
      allPrismicStory(
        sort: { fields: data___story_number, order: DESC }
        limit: 1
      ) {
        edges {
          node {
            _previewable
            uid
            url
            data {
              story_number
              story_color
              title
              description {
                richText
              }
              spotify_id
              youtube_id
              featured_image {
                alt
                gatsbyImageData(width: 600)
              }
            }
          }
        }
      }
    }
  `);

  // eslint-disable-next-line no-unused-vars
  const { data, isPreview } = useMergePrismicPreviewData(staticData);

  const document = data.allPrismicStory.edges[0].node;
  const { url } = document;
  const {
    story_number,
    story_color,
    title,
    description,
    spotify_id,
    youtube_id,
    featured_image,
  } = document.data;

  // Get contrasting colors based on the background color
  const contrastingColor = getContrastingColor(story_color);
  const reverseContrastingColor = getContrastingColor(contrastingColor);

  return (
    <Wrapper story_color={story_color}>
      <Content contrastingColor={contrastingColor}>
        <Link url={{ url: url, link_type: 'Document' }}>
          <StoryDetails reverseContrastingColor={reverseContrastingColor}>
            <NewStoryBadge fill={contrastingColor} />

            <div>Nº {story_number}</div>
          </StoryDetails>

          <Title>{title}</Title>
        </Link>

        <Description>
          <PrismicRichText field={description.richText} />

          <p>
            <Link url={{ url: url, link_type: 'Document' }} tabIndex="-1">
              Read More
            </Link>
          </p>
        </Description>

        {spotify_id || youtube_id ? (
          <ButtonGroup>
            <ListenWatchButton
              story_color={story_color}
              spotify_id={spotify_id}
              contrastingColor={contrastingColor}
              reverseContrastingColor={reverseContrastingColor}
            />

            <ListenWatchButton
              youtube_id={youtube_id}
              contrastingColor={contrastingColor}
              reverseContrastingColor={reverseContrastingColor}
            />
          </ButtonGroup>
        ) : null}
      </Content>

      <ImageWrapper to={url} tabIndex="-1">
        <Image image={featured_image} aspectRatio={1 / 1} loading="eager" />
      </ImageWrapper>
    </Wrapper>
  );
};

export default LatestStory;

/**
 * Styled Components
 *
 */

const Wrapper = styled.section`
  background-color: ${props => props.story_color};
  padding: var(--space-xs);
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;

  @media (${bp.min.sm}) {
    padding: 2rem;
    flex-direction: row;
  }
`;

const Content = styled.div`
  color: ${props => props.contrastingColor};
  display: flex;
  flex-direction: column;
  padding: var(--space-xxs);
  margin-top: var(--space-xs);

  @media (${bp.min.sm}) {
    padding-right: var(--space-m);
  }

  @media (${bp.min.sm_md}) {
    padding: var(--space-s) 0 var(--space-s) var(--space-m);
    max-width: 80rem;
    margin-top: 0;
    margin-right: var(--space-xl);
  }

  a {
    color: ${props => props.contrastingColor};

    &:hover {
      color: currentColor;
    }
  }
`;

const StoryDetails = styled.div`
  font-size: clamp(4.4rem, 5.5vw, 8.4rem);
  color: ${props => props.reverseContrastingColor};
  line-height: 0.99;
  letter-spacing: -0.05em;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: var(--space-xxs);

  @media (${bp.min.sm}) {
    margin-bottom: 0;
    font-weight: var(--font-weight-bold);
  }

  @media (${bp.min.md}) {
    flex-direction: column;
  }

  svg {
    width: 6.4rem;

    @media (${bp.min.md}) {
      margin-bottom: var(--space-l);
      width: 7.4rem;
    }
  }
`;

const Title = styled.h2`
  font-size: clamp(4.4rem, 5.5vw, 8.4rem);
  line-height: 1.1;
  letter-spacing: -0.05em;

  @media (${bp.min.sm}) {
    line-height: 0.99;
    font-weight: var(--font-weight-bold);
  }
`;

const Description = styled.div`
  max-width: 60rem;
  margin-top: var(--space-xs);
  display: none;

  @media (${bp.min.sm}) {
    display: block;
  }

  a {
    display: inline-flex;
    font-weight: var(--font-weight-bold);

    &:hover {
      &:after {
        transform: translateX(0.4rem);
      }
    }

    &:after {
      content: '→';
      margin-left: var(--space-xxxs);
      transition: all 250ms var(--ease-cubic-in-out);
    }
  }
`;

const ImageWrapper = styled(GatsbyLink)`
  display: flex;

  .image {
    height: 100%;
  }

  @media (${bp.min.sm}) {
    max-width: 60rem;
  }
`;

const ButtonGroup = styled.div`
  margin-top: auto;
  padding-top: var(--space-xl);
  display: flex;
`;
