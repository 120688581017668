import React from 'react';
import { Script } from 'gatsby';
import styled from 'styled-components';

import bp from '../assets/js/breakpoints';

const EventsWidget = () => {
  return (
    <Wrapper>
      <Title>Coming Events</Title>

      <div data-cswidget="11289" />
      <Script
        type="text/javascript"
        src="//cdn.cityspark.com/wid/get.js"
        async
        defer
      />
    </Wrapper>
  );
};

export default EventsWidget;

/**
 * Styled Components
 *
 */
const Wrapper = styled.section`
  padding: 4rem 0;
  position: relative;

  @media (${bp.min.sm}) {
    padding: var(--space-xxl) 0;
  }

  &:after {
    content: '';
    width: 100vw;
    height: 100%;
    background-color: var(--color-snow);
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    z-index: -1;
  }

  .csGWrap[data-v-3c7bf9aa] {
    max-width: initial;
  }

  .csButtons[data-v-766e37ac] {
    justify-content: flex-start;
  }
`;

const Title = styled.h2`
  font-weight: var(--font-weight-bold);
  font-size: clamp(4.4rem, 3.788rem + 3.06vw, 7rem);
  line-height: 0.85;
  letter-spacing: -0.4rem;
  margin-bottom: var(--space-m);

  @media (${bp.min.sm}) {
    margin-bottom: var(--space-l);
  }
`;
