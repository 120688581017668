import React, { useEffect, useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import {
  withPrismicPreview,
  useMergePrismicPreviewData,
} from 'gatsby-plugin-prismic-previews';

import { GlobalContext } from '../components/GlobalContext';

import bp from '../assets/js/breakpoints';

import Seo from '../components/Seo';
import LatestStory from '../components/LatestStory';
import HomepageHero from '../components/HomepageHero';
import RecentStoriesSlider from '../components/RecentStoriesSlider';
import LocalJobs from '../components/LocalJobs';
import Social from '../components/Social';
import EventsWidget from '../components/EventsWidget';
import HomepageSlider from '../components/HomepageSlider';
import HomepageLinkGrid from '../components/HomepageLinkGrid';

const IndexPage = () => {
  const staticData = useStaticQuery(graphql`
    {
      prismicHomepage {
        _previewable
        data {
          recent_stories_header
          recent_stories_description {
            text
          }
        }
      }
    }
  `);

  // eslint-disable-next-line no-unused-vars
  const { data, isPreview } = useMergePrismicPreviewData(staticData);

  const { setStoryHeaderColor, setPageBgColor } = useContext(GlobalContext);

  useEffect(() => {
    setPageBgColor('var(--color-snow)');
    setStoryHeaderColor('var(--color-snow)');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HomepageHero />

      <HomepageSlider />

      <HomepageLinkGrid />

      <Social />

      <LocalJobs />

      <StoriesWrapper>
        <IntroText>
          <h2>{data.prismicHomepage.data.recent_stories_header}</h2>
          <p>{data.prismicHomepage.data.recent_stories_description.text}</p>
        </IntroText>

        <LatestStory />
        <RecentStoriesSlider variant="no-title" />
      </StoriesWrapper>

      <EventsWidget />
    </>
  );
};

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />;

export default withPrismicPreview(IndexPage);

/**
 * Styled Components
 *
 */
const StoriesWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: var(--space-xxl) 0 var(--space-xl);

  @media (${bp.min.sm}) {
    padding: var(--space-xxl) 0;
  }

  &:after {
    content: '';
    width: 100vw;
    height: 100%;
    background-color: var(--color-snow);
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    z-index: -1;
  }
`;

const IntroText = styled.div`
  text-align: center;
  width: 100%;
  max-width: 60rem;
  margin: 0 auto 4.8rem;

  h2 {
    font-size: clamp(4.4rem, 5.5vw, 8.4rem);
    line-height: 1.1;
    letter-spacing: -0.05em;
    margin-bottom: var(--space-xs);

    @media (${bp.min.sm}) {
      line-height: 0.99;
      font-weight: var(--font-weight-bold);
    }
  }
`;
