import React, { forwardRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

import bp from '../../assets/js/breakpoints';
import { toKebabCase } from '../../assets/js/utilities';

import SocialIcon from '../Icons/SocialIcon';

const SocialMobile = (props, ref) => {
  const staticData = useStaticQuery(graphql`
    {
      prismicGlobalLayout {
        _previewable
        ...SocialItems
      }
    }
  `);

  // eslint-disable-next-line no-unused-vars
  const { data, isPreview } = useMergePrismicPreviewData(staticData);

  const { social_menu } = data.prismicGlobalLayout.data;

  return (
    <Wrapper ref={ref}>
      <Title>Follow Us</Title>
      <SocialPlatforms>
        {social_menu.map(item => (
          <li key={item.link_text}>
            <a href={item.link_url.url} target="_blank" rel="noreferrer">
              {item.link_text}
              <SocialIcon platform={toKebabCase(item.link_text)} />
            </a>
          </li>
        ))}
      </SocialPlatforms>
    </Wrapper>
  );
};

export default forwardRef(SocialMobile);

/**
 * Styled Components
 *
 */
const Wrapper = styled.section`
  margin-top: var(--space-l);
  margin-bottom: 12rem;
  display: block;

  @media (${bp.min.sm_md}) {
    display: none;
  }
`;

const Title = styled.h2`
  font-size: 1.6rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: var(--font-weight-bold);
`;

const SocialPlatforms = styled.ul`
  li {
    font-size: 2.8rem;
    line-height: 1;
    letter-spacing: -0.15rem;
    font-weight: var(--font-weight-bold);
    border-bottom: 0.3rem solid var(--color-navy);

    a {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding: 2rem 0;

      &:hover {
        svg {
          fill: var(--color-great-blue);
        }
      }

      svg {
        transition: all 250ms var(--ease-cubic-in-out);
      }
    }
  }
`;
