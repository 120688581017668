import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { PrismicRichText } from '@prismicio/react';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

import bp from '../assets/js/breakpoints';

import Button from './Button';
import Link from './Link';
import Image from './Image';

const LocalJobs = () => {
  const staticData = useStaticQuery(graphql`
    {
      prismicHomepage {
        _previewable
        data {
          local_jobs_subheader
          local_jobs_callouts {
            title
            body {
              richText
            }
            link_text
            link_url {
              link_type
              target
              url
            }
            image {
              alt
              gatsbyImageData(width: 600)
            }
          }
        }
      }
    }
  `);

  // eslint-disable-next-line no-unused-vars
  const { data, isPreview } = useMergePrismicPreviewData(staticData);

  const document = data.prismicHomepage;
  const { local_jobs_subheader, local_jobs_callouts } = document.data;

  return (
    <Wrapper>
      <Header>
        <Title>
          <svg viewBox="0 0 63 11.1">
            <text x="-0.5" y="10.9">
              Local Jobs
            </text>
          </svg>
        </Title>
        <Subtitle>{local_jobs_subheader}</Subtitle>
      </Header>

      <CalloutContainer>
        {local_jobs_callouts.map(callout => (
          <Callout key={callout.title}>
            <Link url={callout.link_url}>
              <CalloutHeader>
                <CalloutTitle>{callout.title}</CalloutTitle>
                <Button as="div" withArrow>
                  {callout.link_text}
                </Button>
              </CalloutHeader>
              <Description>
                <PrismicRichText field={callout.body.richText} />
              </Description>

              <Image image={callout.image} />

              <ButtonFooter>
                <Button as="div" withArrow>
                  {callout.link_text}
                </Button>
              </ButtonFooter>
            </Link>
          </Callout>
        ))}
      </CalloutContainer>
    </Wrapper>
  );
};

export default LocalJobs;

/**
 * Styled Components
 *
 */
const Wrapper = styled.section`
  padding-bottom: var(--space-xl);

  @media (${bp.min.sm}) {
    margin-top: var(--space-l);
    padding-bottom: 6rem;
  }
`;

const Header = styled.header`
  border-top: 0.5rem solid var(--color-navy);
  margin: var(--space-l) 0 4.8rem;

  @media (${bp.min.sm}) {
    border-top-width: 0.1rem;
    border-bottom: 0.1rem solid var(--color-navy);
  }
`;

const Title = styled.h2`
  padding: var(--space-m) 0;

  @media (${bp.min.sm}) {
    padding: var(--space-l) 0;
  }

  svg {
    width: 100%;
    font-family: var(--font-family-national-condensed);
    font-weight: var(--font-weight-extrabold);
    font-size: 1.6rem;
    text-transform: uppercase;
    letter-spacing: -0.02rem;
    fill: var(--color-navy);
  }
`;

const Subtitle = styled.p`
  font-size: 2.4rem;
  line-height: 1;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  text-align: center;

  @media (${bp.min.sm}) {
    padding: var(--space-s) 0;
    border-top: 0.1rem solid var(--color-navy);
  }
`;

const CalloutContainer = styled.div`
  border: 0.1rem solid var(--color-navy);

  @media (${bp.min.sm}) {
    display: flex;
  }
`;

const Callout = styled.div`
  padding: var(--space-m) var(--space-s);
  transition: all 250ms var(--ease-cubic-in-out);

  @media (${bp.min.sm}) {
    width: 50%;
    padding: var(--space-l);
  }

  @media (${bp.min.sm_md}) {
    padding: var(--space-xl);
  }

  @media (${bp.min.lg}) {
    padding: 5.6rem var(--space-xl) var(--space-xl);
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-baybee-blue);

      a {
        color: var(--color-navy);
      }
    }
  }

  & + & {
    border-top: 0.1rem solid var(--color-navy);

    @media (${bp.min.sm}) {
      border-top: 0;
      border-left: 0.1rem solid var(--color-navy);
    }
  }

  .link-tag {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .image-wrapper {
    margin-bottom: var(--space-m);

    @media (${bp.min.lg}) {
      margin-bottom: 0;
    }
  }

  img {
    aspect-ratio: 3 / 2;

    @media (${bp.min.sm}) {
      aspect-ratio: 4 / 3;
    }
  }
`;

const CalloutHeader = styled.header`
  margin-bottom: var(--space-xl);

  @media (${bp.min.lg}) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: var(--space-xxl);
  }

  .button-wrapper {
    display: none;

    @media (${bp.min.lg}) {
      display: flex;
      flex: 0 0 auto;
      margin-left: var(--space-m);
    }
  }
`;

const CalloutTitle = styled.h3`
  font-weight: var(--font-weight-bold);
  font-size: clamp(4.4rem, 3.788rem + 3.06vw, 7rem);
  line-height: 0.85;
  letter-spacing: -0.4rem;
`;

const Description = styled.div`
  @media (${bp.max.lg}) {
    order: 3;
    padding-bottom: var(--space-s);
  }

  margin-bottom: auto;
  padding-bottom: var(--space-l);
`;

const ButtonFooter = styled.div`
  display: flex;
  order: 4;

  @media (${bp.min.lg}) {
    display: none;
  }
`;
