import React from 'react';

const NewStoryBadge = ({ fill = 'var(--color-navy)' }) => {
  return (
    <svg
      width="74"
      height="28"
      viewBox="0 0 74 28"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>New Story Badge</title>
      <path d="M29.1948 6.89309C29.7661 5.95705 30.4861 5.15542 31.3309 4.50739C32.1854 3.89776 33.0206 3.61455 33.9614 3.61455C34.5711 3.61455 35.0943 3.74896 35.5599 4.01777L36.2511 4.42099L36.2847 3.62415C36.3327 2.4529 36.1791 1.56007 35.8239 0.95044C35.5503 0.518422 34.9263 0 33.5342 0C33.2606 0 32.9726 0 32.7374 0.048002C29.9005 0.460819 27.4092 3.28334 25.3403 8.43395C24.5002 10.5076 23.2762 14.367 21.6921 19.9208C21.4905 18.716 21.1785 17.0071 20.7321 14.6406C19.9448 10.6948 19.5416 7.38271 19.5416 4.8002C19.5416 4.43059 19.5656 3.91696 19.5944 3.26414L19.6472 2.01128H15.9607L15.8599 2.3569C11.6357 16.6423 6.60508 23.8906 0.916839 23.8906H0L0.0528022 24.4186C0.153606 25.4267 0.566424 26.2523 1.26245 26.8523C1.96328 27.5532 2.88492 27.894 4.08497 27.894C8.86597 27.894 12.7493 22.1577 15.9271 10.378C16.1575 12.5429 16.5511 14.895 17.0983 17.4055C17.3959 18.8456 17.6887 20.2905 17.9336 21.7449C18.092 22.6858 18.2216 23.6746 18.1208 24.649C18.0392 25.4555 17.7799 26.0075 17.3335 26.6507H22.4553L22.5658 26.3243C23.0362 24.9178 23.5978 23.0986 24.241 20.9145C24.7834 19.076 25.2875 17.2951 25.7867 15.4663C26.1323 14.2038 26.6315 12.7349 27.3564 10.8485C28.0812 8.96678 28.6476 7.75233 29.1996 6.90269L29.1948 6.89309Z" />
      <path d="M63.3146 9.75908H59.7144L59.6904 10.2151C59.6088 11.9288 58.9608 14.0265 57.7703 16.4506C56.8919 18.2314 56.1239 19.6043 55.4471 20.5931L56.5511 9.75908H52.3797L47.5747 19.6187L48.722 9.75908H44.1378L44.037 10.1095C43.4945 12.02 42.7649 13.8921 41.8385 15.7977C40.6816 17.881 39.328 19.6235 37.8063 20.9675C36.0206 22.5084 34.4414 23.2908 33.1117 23.2908C32.2909 23.2908 31.1964 23.0124 31.1964 20.6027C31.1964 19.6955 31.374 18.6443 31.7436 17.401C32.4877 17.6842 33.2509 17.8282 34.0093 17.8282C35.5982 17.8282 37.0239 17.3482 38.2335 16.4074C39.5296 15.4473 40.1824 14.1945 40.1824 12.6824C40.1824 11.6552 39.8704 10.8439 39.2128 10.1911C38.5599 9.58627 37.6671 9.29346 36.491 9.29346C33.9949 9.29346 31.7485 10.6423 29.8092 13.3016C27.9323 15.8793 26.9771 18.6106 26.9771 21.4188C26.9771 22.9356 27.4523 24.2221 28.3787 25.2445C29.3147 26.3198 30.5436 26.867 32.0365 26.867C34.1918 26.867 36.4526 25.8062 38.9488 23.6221C41.2 21.6156 42.9953 19.1723 44.3058 16.345C44.373 16.2201 44.445 16.0953 44.5074 15.9705L43.2689 26.651H46.9363L52.5237 15.1113L51.2373 26.651H54.8182L54.9478 26.3726C55.2406 25.7486 56.0087 24.3805 57.2375 22.3068C58.5528 20.1275 59.7336 18.049 60.8473 15.9465C61.9897 13.7864 62.7482 11.9624 63.1562 10.3639L63.3098 9.76388L63.3146 9.75908ZM34.2734 12.7544L34.283 12.7448C34.8926 12.0632 35.5358 11.708 36.1502 11.708C36.5055 11.708 36.8271 11.7704 36.8271 12.5576C36.8271 13.3448 36.5151 13.9449 35.8766 14.5113C35.2286 15.0873 34.4414 15.3753 33.5389 15.3753C33.1981 15.3753 32.8765 15.3417 32.5789 15.2697C33.0685 14.2809 33.6493 13.4216 34.2734 12.764V12.7544Z" />
      <path d="M68.2205 2.01099L68.1245 2.4478C67.7164 4.31988 66.8428 6.984 65.4459 10.5937C64.1451 13.9827 63.089 16.4212 61.5914 19.1525L61.2266 19.8197H63.9531L64.1115 19.5029C65.3259 17.0548 66.5452 14.7315 68.7293 10.7714C70.9806 6.70078 72.5838 4.14228 73.5295 2.72142L73.9999 2.01579H68.2205V2.01099Z" />
      <path d="M61.0534 21.6921C60.2566 21.6921 59.5701 21.9706 59.0709 22.4938C58.5237 23.065 58.2357 23.689 58.1973 24.4091C58.1589 25.1291 58.3461 25.6475 58.7973 26.1275C59.2677 26.6267 59.8149 26.8716 60.463 26.8716C61.2022 26.8716 61.8598 26.5835 62.479 25.9979C63.0647 25.4411 63.3719 24.8075 63.4151 24.0682C63.4535 23.3722 63.2663 22.8298 62.7959 22.3306C62.3494 21.9129 61.7494 21.6921 61.0582 21.6921H61.0534Z" />
    </svg>
  );
};

export default NewStoryBadge;
