import React, { useRef, forwardRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import { useIsomorphicLayoutEffect } from 'react-use';

import bp from '../../assets/js/breakpoints';
import { horizontalLoop } from '../../assets/js/utilities';

const SocialDesktop = (props, ref) => {
  const staticData = useStaticQuery(graphql`
    {
      prismicHomepage {
        _previewable
        data {
          social_scroller {
            text
          }
        }
      }
      prismicGlobalLayout {
        _previewable
        ...SocialItems
      }
    }
  `);

  // eslint-disable-next-line no-unused-vars
  const { data, isPreview } = useMergePrismicPreviewData(staticData);

  const { social_scroller } = data.prismicHomepage.data;
  const { social_menu } = data.prismicGlobalLayout.data;

  const followUs = useRef(null);
  const socialPlatforms = useRef(null);
  const loop1 = useRef(null);
  const loop2 = useRef(null);

  useIsomorphicLayoutEffect(() => {
    document.fonts.ready.then(function () {
      const query = gsap.utils.selector(followUs);

      const listEls = query('li:not(.sr-only)');

      loop1.current = horizontalLoop(gsap, null, null, listEls, {
        paused: false,
        center: true,
        speed: 1,
        repeat: -1,
      });
    });

    return () => {
      loop1.current.kill();
    };
  }, []);

  useIsomorphicLayoutEffect(() => {
    document.fonts.ready.then(function () {
      const query = gsap.utils.selector(socialPlatforms);

      const listEls = query('li');

      loop2.current = horizontalLoop(gsap, null, null, listEls, {
        paused: false,
        center: true,
        speed: 0.65,
        repeat: -1,
      });
    });

    return () => {
      loop2.current.kill();
    };
  }, []);

  return (
    <Wrapper className="full-width-container" ref={ref}>
      <FollowUs ref={followUs}>
        <li className="sr-only">
          <h2>Follow Us</h2>
        </li>
        {/* Duplicating the array to fill screen width so it loops properly */}
        {[...social_scroller, ...social_scroller].map((item, i) => (
          <li key={i}>{item.text}</li>
        ))}
      </FollowUs>

      <SocialPlatforms ref={socialPlatforms}>
        {social_menu.map(item => (
          <li key={item.link_text}>
            <a href={item.link_url.url} target="_blank" rel="noreferrer">
              {item.link_text}
            </a>
          </li>
        ))}
      </SocialPlatforms>
    </Wrapper>
  );
};

export default forwardRef(SocialDesktop);

/**
 * Styled Components
 *
 */
const Wrapper = styled.section`
  margin-top: var(--space-l);
  overflow: hidden;
  display: none;

  @media (${bp.min.sm_md}) {
    display: block;
  }

  @media (min-width: 1800px) {
    width: 100%;
    max-width: var(--width-max);
    overflow: hidden;
    position: static;
    margin-left: 0;
    margin-right: 0;
  }
`;

const FollowUs = styled.ul`
  display: flex;
  margin-bottom: var(--space-m);

  li {
    font-size: 2.4rem;
    line-height: 1;
    text-transform: uppercase;
    font-weight: var(--font-weight-bold);
    padding: 0 3.6rem;
    white-space: nowrap;
  }
`;

const SocialPlatforms = styled.ul`
  display: flex;

  li {
    font-family: var(--font-family-national-condensed);
    font-size: clamp(4.4rem, 0.852rem + 7.57vw, 8.8rem);
    line-height: 1;
    letter-spacing: -0.04rem;
    font-weight: var(--font-weight-extrabold);
    padding: 0 3.6rem;
    white-space: nowrap;
  }
`;
