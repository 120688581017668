import React, { useContext, useRef } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { GlobalContext } from '../../components/GlobalContext';

import SocialDesktop from './SocialDesktop';
import SocialMobile from './SocialMobile';

gsap.registerPlugin(ScrollTrigger);

const Social = () => {
  const { setPageBgColor } = useContext(GlobalContext);
  const socialEl = useRef(null);

  // Update page background color when section is visible
  useIsomorphicLayoutEffect(() => {
    if (!socialEl) return;

    ScrollTrigger.create({
      trigger: socialEl.current,
      start: 'top 30%',
      end: 'bottom 0%',
      onEnter: () => {
        setPageBgColor('#DDEAE8');
      },
      onLeaveBack: () => {
        setPageBgColor('var(--color-snow)');
      },
    });

    return () => {
      ScrollTrigger.killAll();
    };
  }, [socialEl]);

  return (
    <>
      <SocialDesktop ref={socialEl} />
      <SocialMobile ref={socialEl} />
    </>
  );
};

export default Social;
