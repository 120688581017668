import React, { useEffect, useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import axios from 'axios';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

import { GlobalContext } from '../components/GlobalContext';

import bp from '../assets/js/breakpoints';

import Logo from './Logo';

const HomepageHero = () => {
  const staticData = useStaticQuery(graphql`
    {
      prismicHomepage {
        _previewable
        data {
          desktop_tagline
          mobile_tagline
        }
      }
    }
  `);

  // eslint-disable-next-line no-unused-vars
  const { data, isPreview } = useMergePrismicPreviewData(staticData);

  const document = data.prismicHomepage;
  const { desktop_tagline, mobile_tagline } = document.data;

  const { currentTemp, setCurrentTemp, currentDate, setCurrentDate } =
    useContext(GlobalContext);

  useEffect(() => {
    const openWeatherApiKey = 'e55c044a4e6e050c6f22c2965a51477d';
    const latLon = {
      lat: 43.581248,
      lon: -83.883535,
    };

    axios
      .get(
        `https://api.openweathermap.org/data/2.5/weather?lat=${latLon.lat}&lon=${latLon.lon}&units=imperial&appid=${openWeatherApiKey}`
      )
      .then(res => {
        const data = res.data;

        const temp = Math.round(data.main.temp);

        const date = new Date(data.dt * 1000);
        const options = { weekday: 'short', month: 'short', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-us', options);

        setCurrentTemp(temp);
        setCurrentDate(formattedDate);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <HeadingDesktop>
        <span className="sr-only">Hey, Bay City!</span>

        <Logo />
      </HeadingDesktop>

      <HeadingMobile>{mobile_tagline}</HeadingMobile>

      <Currently>
        <Column>
          <span>Date</span>
          {currentDate}
        </Column>

        <Column as="h1">{desktop_tagline}</Column>

        <Column>
          <span>Temperature</span>
          {currentTemp}º F
        </Column>
      </Currently>
    </Wrapper>
  );
};

export default HomepageHero;

/**
 * Styled Components
 *
 */
const Wrapper = styled.section`
  padding-top: 10.8rem;
  margin-bottom: var(--space-m);

  @media (${bp.min.sm_md}) {
    margin-bottom: -0.1rem;
  }
`;

const HeadingDesktop = styled.div`
  display: none;
  padding: var(--space-m) 0 var(--space-s);

  @media (${bp.min.sm_md}) {
    display: block;
  }

  .logo {
    width: 100%;
    height: auto;
  }
`;

const HeadingMobile = styled.h1`
  font-family: var(--font-family-national-condensed);
  font-size: clamp(4rem, 1.412rem + 12.94vw, 15rem);
  line-height: 0.8;
  letter-spacing: -0.02rem;
  text-transform: uppercase;
  text-align: center;

  @media (${bp.min.sm_md}) {
    display: none;
  }
`;

const Currently = styled.div`
  display: none;
  border-top: 0.1rem solid var(--color-navy);
  border-bottom: 0.1rem solid var(--color-navy);
  padding: var(--space-xs) 0;
  grid-template-columns: 1fr 2fr 1fr;
  margin-top: var(--space-s);

  @media (${bp.min.sm_md}) {
    display: grid;
    margin-top: 0;
  }
`;

const Column = styled.div`
  font-size: clamp(1rem, 0.671rem + 1.65vw, 2.4rem);
  line-height: 1;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  letter-spacing: -0.02rem;
  display: flex;
  align-items: center;
  flex-grow: 1;
  text-align: center;

  &:nth-child(2) {
    justify-content: center;
  }

  &:nth-child(3) {
    justify-content: flex-end;
  }

  span {
    font-size: 1.4rem;
    margin-right: var(--space-xs);
  }
`;
